import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Services from './pages/Services';
import Cases from './pages/Cases';
import Contact from './pages/Contact';
import './styles/global.css';
import './styles/animations.css';
import About from './pages/About';

const { Content } = Layout;

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Header />
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/cases" element={<Cases />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Content>
        <Layout.Footer style={{ textAlign: 'center' }}>
          © 2024 郑州寻路科技. All Rights Reserved.
        </Layout.Footer>
      </Layout>
    </BrowserRouter>
  );
};

export default App; 