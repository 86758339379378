import React from 'react';
import { Typography, Row, Col, Card, Divider } from 'antd';
import { motion } from 'framer-motion';
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  ClockCircleOutlined,
  WechatOutlined,
  CompassOutlined,
  TeamOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import '../styles/Contact.css';

const { Title, Paragraph, Text } = Typography;

const Contact: React.FC = () => {
  const contactInfo = [
    {
      icon: <EnvironmentOutlined className="info-icon" />,
      title: '公司地址',
      content: '河南省郑州市中原区三官庙街道华山路105号芝麻街公园里D区10栋102-01',
      color: '#1890ff'
    },
    {
      icon: <PhoneOutlined className="info-icon" />,
      title: '联系电话',
      content: '17398982191',
      color: '#722ed1'
    },
    {
      icon: <MailOutlined className="info-icon" />,
      title: '电子邮箱',
      content: 'admin@xunluglobal.com',
      color: '#13c2c2'
    },
    {
      icon: <ClockCircleOutlined className="info-icon" />,
      title: '工作时间',
      content: '周一至周五 9:00-18:00',
      color: '#52c41a'
    }
  ];

  const advantages = [
    {
      icon: <CompassOutlined />,
      title: '专业团队',
      desc: '拥有多年开发经验的技术团队'
    },
    {
      icon: <TeamOutlined />,
      title: '贴心服务',
      desc: '一对一专属客服，及时响应需求'
    },
    {
      icon: <SafetyCertificateOutlined />,
      title: '品质保证',
      desc: '严格的开发规范，确保项目质量'
    }
  ];

  return (
    <div className="contact-page">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="page-header"
      >
        <Title>联系我们</Title>
        <Paragraph className="page-desc">
          无论您有任何问题或需求，我们都将第一时间为您提供专业的解答和服务
        </Paragraph>
      </motion.div>

      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <Card className="contact-card main-info">
            <Row gutter={[24, 24]}>
              {contactInfo.map((info, index) => (
                <Col xs={24} sm={12} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.1 }}
                  >
                    <Card 
                      className="info-card"
                      style={{ borderTop: `3px solid ${info.color}` }}
                    >
                      {info.icon}
                      <Title level={4}>{info.title}</Title>
                      <Text>{info.content}</Text>
                    </Card>
                  </motion.div>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>

        <Col xs={24} lg={8}>
          <Card className="contact-card quick-contact">
            <div className="wechat-section">
              <WechatOutlined className="wechat-icon" />
              <Title level={3}>微信咨询</Title>
              <Divider />
              <div className="qrcode-wrapper">
                <img src="/images/qrcode.png" alt="微信二维码" />
                <Paragraph className="qrcode-tip">
                  扫描二维码，添加客服微信
                </Paragraph>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Card className="advantages-card">
        <Title level={3} className="section-title">为什么选择我们</Title>
        <Row gutter={[24, 24]}>
          {advantages.map((advantage, index) => (
            <Col xs={24} md={8} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.5 + index * 0.1 }}
                className="advantage-item"
              >
                <div className="advantage-icon">{advantage.icon}</div>
                <Title level={4}>{advantage.title}</Title>
                <Paragraph>{advantage.desc}</Paragraph>
              </motion.div>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default Contact; 