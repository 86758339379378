import React from 'react';
import { Typography, Card, Tag, Row, Col } from 'antd';
import { motion } from 'framer-motion';
import { 
  ShoppingOutlined, 
  MobileOutlined, 
  CustomerServiceOutlined,
  ApiOutlined,
  SolutionOutlined,
  RocketOutlined
} from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

const Cases: React.FC = () => {
  const solutions = [
    {
      icon: <ShoppingOutlined className="solution-icon" />,
      title: '电商平台分销系统',
      subtitle: 'E-commerce Distribution System',
      color: '#1890ff',
      platforms: ['美团', '饿了么', '淘宝', '京东', '拼多多'],
      features: [
        '多平台数据对接',
        '订单自动分发',
        '库存实时同步',
        '数据统计分析'
      ],
      techStack: ['Java', 'Spring Cloud', 'MySQL', 'Redis', 'RabbitMQ'],
      highlights: [
        '支持主流电商平台对接',
        '分布式架构设计',
        '高并发订单处理',
        '完整的管理后台'
      ]
    },
    {
      icon: <MobileOutlined className="solution-icon" />,
      title: '短视频带货解决方案',
      subtitle: 'Social Media E-commerce Solution',
      color: '#722ed1',
      platforms: ['抖音', '快手'],
      features: [
        '直播间数据对接',
        '商品实时同步',
        '订单自动处理',
        '数据实时统计'
      ],
      techStack: ['Python', 'Django', 'MongoDB', 'Celery', 'WebSocket'],
      highlights: [
        '直播数据实时同步',
        '智能订单分发',
        '多平台数据整合',
        '完整的运营后台'
      ]
    },
    {
      icon: <CustomerServiceOutlined className="solution-icon" />,
      title: '本地生活服务系统',
      subtitle: 'Local Life Service System',
      color: '#13c2c2',
      platforms: ['上门按摩', '家政服务'],
      features: [
        '订单智能派单',
        '服务人员管理',
        '用户评价系统',
        '数据统计分析'
      ],
      techStack: ['PHP', 'Laravel', 'MySQL', 'Redis', 'WebSocket'],
      highlights: [
        '智能派单系统',
        '服务质量监控',
        '多端应用支持',
        '完整的管理系统'
      ]
    }
  ];

  return (
    <div className="cases-page">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="page-header"
      >
        <Title>解决方案</Title>
        <Paragraph className="page-desc">
          基于多年技术积累，我们为不同行业打造了一系列成熟的解决方案
        </Paragraph>
      </motion.div>

      <Row gutter={[24, 24]}>
        {solutions.map((solution, index) => (
          <Col xs={24} key={index}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <Card 
                className="solution-card"
                style={{ borderLeft: `4px solid ${solution.color}` }}
              >
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={6}>
                    <div className="solution-intro" style={{ color: solution.color }}>
                      {solution.icon}
                      <Title level={3}>{solution.title}</Title>
                      <Text type="secondary" className="subtitle">{solution.subtitle}</Text>
                    </div>
                  </Col>
                  <Col xs={24} md={18}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12}>
                        <Card className="detail-card feature-card">
                          <Title level={4}><ApiOutlined /> 功能特点</Title>
                          <div className="feature-list">
                            {solution.features.map((feature, idx) => (
                              <Tag key={idx} color={solution.color}>{feature}</Tag>
                            ))}
                          </div>
                        </Card>
                      </Col>
                      <Col xs={24} md={12}>
                        <Card className="detail-card tech-card">
                          <Title level={4}><RocketOutlined /> 技术栈</Title>
                          <div className="tech-list">
                            {solution.techStack.map((tech, idx) => (
                              <Tag key={idx} color="blue">{tech}</Tag>
                            ))}
                          </div>
                        </Card>
                      </Col>
                      <Col xs={24}>
                        <Card className="detail-card highlight-card">
                          <Title level={4}><SolutionOutlined /> 方案亮点</Title>
                          <Row gutter={[16, 16]}>
                            {solution.highlights.map((highlight, idx) => (
                              <Col xs={24} sm={12} md={6} key={idx}>
                                <div className="highlight-item">
                                  {highlight}
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Cases; 