import React from 'react';
import { Typography, Row, Col, Card, Timeline, Image } from 'antd';
import { 
  CompassOutlined, 
  SafetyCertificateOutlined, 
  TeamOutlined, 
  RocketOutlined,
  CheckCircleOutlined 
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import '../styles/About.css';

const { Title, Paragraph } = Typography;

const About: React.FC = () => {
  const companyValues = [
    {
      icon: <CompassOutlined className="value-icon" />,
      title: "技术引领",
      desc: "核心团队来自知名互联网公司，拥有丰富的企业级项目开发经验"
    },
    {
      icon: <SafetyCertificateOutlined className="value-icon" />,
      title: "品质保证",
      desc: "严格的代码审查机制，规范的项目管理流程，确保交付质量"
    },
    {
      icon: <TeamOutlined className="value-icon" />,
      title: "专业团队",
      desc: "全栈工程师团队，擅长Java、Python、前端等多个技术领域"
    }
  ];

  const advantages = [
    {
      year: "技术优势",
      title: "全栈开发能力",
      content: "团队成员均具备全栈开发能力，可快速响应各类开发需求"
    },
    {
      year: "服务优势",
      title: "专属项目经理",
      content: "一对一专属服务，确保沟通顺畅，快速响应需求变更"
    },
    {
      year: "质量优势",
      title: "标准化交付",
      content: "采用业界领先的敏捷开发流程，确保项目如期高质量交付"
    },
    {
      year: "成本优势",
      title: "性价比保证",
      content: "合理的报价体系，为客户提供最优性价比的解决方案"
    }
  ];

  return (
    <div className="about-page">
      {/* 公司简介 */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="company-intro"
      >
        <Title level={2}>
          <CompassOutlined className="section-icon" /> 关于寻路科技
        </Title>
        <Paragraph className="intro-text">
          郑州寻路科技有限公司成立于2023年8月，是一家专注于企业数字化转型的新锐技术公司。
          公司核心团队来自知名互联网企业，拥有丰富的项目开发经验。我们致力于为企业提供高质量、
          可靠的软件开发服务，帮助企业在数字化转型的道路上找到最适合的解决方案。
        </Paragraph>
      </motion.div>

      {/* 核心价值观 */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="values-section"
      >
        <Title level={2}>
          <RocketOutlined className="section-icon" /> 我们的优势
        </Title>
        <Row gutter={[24, 24]}>
          {companyValues.map((value, index) => (
            <Col key={index} xs={24} sm={8}>
              <motion.div
                whileHover={{ y: -10 }}
                transition={{ duration: 0.2 }}
              >
                <Card className="value-card">
                  {value.icon}
                  <Title level={3}>{value.title}</Title>
                  <Paragraph>{value.desc}</Paragraph>
                </Card>
              </motion.div>
            </Col>
          ))}
        </Row>
      </motion.div>

      {/* 服务承诺 */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="timeline-section"
      >
        <Title level={2}>
          <CheckCircleOutlined className="section-icon" /> 服务承诺
        </Title>
        <Timeline mode="alternate" className="company-timeline">
          {advantages.map((advantage, index) => (
            <Timeline.Item 
              key={index}
              dot={<CompassOutlined className="timeline-dot" />}
            >
              <Card className="timeline-card">
                <Title level={4}>{advantage.year}</Title>
                <Title level={5}>{advantage.title}</Title>
                <Paragraph>{advantage.content}</Paragraph>
              </Card>
            </Timeline.Item>
          ))}
        </Timeline>
      </motion.div>
    </div>
  );
};

export default About; 