import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { HomeOutlined, AppstoreOutlined, ProjectOutlined, TeamOutlined, ContactsOutlined, CompassOutlined } from '@ant-design/icons';
import '../styles/Header.css';

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  return (
    <AntHeader className="site-header">
      <div className="header-logo">
        <CompassOutlined className="logo-icon" />
        <Link to="/" className="logo-text">
          寻路科技
        </Link>
      </div>
      <Menu theme="dark" mode="horizontal" className="site-menu">
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">首页</Link>
        </Menu.Item>
        <Menu.Item key="services" icon={<AppstoreOutlined />}>
          <Link to="/services">服务介绍</Link>
        </Menu.Item>
        <Menu.Item key="cases" icon={<ProjectOutlined />}>
          <Link to="/cases">解决方案</Link>
        </Menu.Item>
        <Menu.Item key="about" icon={<TeamOutlined />}>
          <Link to="/about">关于我们</Link>
        </Menu.Item>
        <Menu.Item key="contact" icon={<ContactsOutlined />}>
          <Link to="/contact">联系我们</Link>
        </Menu.Item>
      </Menu>
    </AntHeader>
  );
};

export default Header; 