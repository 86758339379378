import React from 'react';
import { Modal, Typography } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import '../styles/QRCodeModal.css';

const { Title, Paragraph } = Typography;

interface QRCodeModalProps {
  visible: boolean;
  onClose: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ visible, onClose }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={320}
      className="qrcode-modal"
      centered
    >
      <div className="qrcode-content">
        <QrcodeOutlined className="qrcode-icon" />
        <Title level={4}>微信咨询</Title>
        <div className="qrcode-image">
          {/* 替换为你的二维码图片 */}
          <img src="/images/qrcode.png" alt="微信二维码" />
        </div>
        <Paragraph className="qrcode-tip">
          扫描二维码，添加客服微信
        </Paragraph>
      </div>
    </Modal>
  );
};

export default QRCodeModal; 