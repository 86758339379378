import React, { useState } from 'react';
import { Typography, Row, Col, Card, Button, Statistic } from 'antd';
import { RocketOutlined, TeamOutlined, CodeOutlined, ApiOutlined, SolutionOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import QRCodeModal from '../components/QRCodeModal';
import '../styles/Home.css';

const { Title, Paragraph } = Typography;

const Home: React.FC = () => {
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);

  return (
    <div className="home-page">
      {/* Hero Section */}
      <motion.div 
        className="hero-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Title>郑州寻路科技 | 让软件外包省心高效</Title>
        <Paragraph style={{ fontSize: '1.2rem', marginBottom: '32px' }}>
          一站式定制开发专家，为您提供专业的软件开发解决方案
        </Paragraph>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Button 
            type="primary" 
            size="large" 
            shape="round"
            onClick={() => setIsQRCodeVisible(true)}
          >
            立即咨询
          </Button>
        </motion.div>
      </motion.div>

      {/* Core Services */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <Row gutter={[24, 24]}>
          {[
            {
              title: "软件项目全包",
              icon: <RocketOutlined />,
              desc: "从需求分析到上线运维，一站式服务"
            },
            {
              title: "人力外派",
              icon: <TeamOutlined />,
              desc: "Java/PHP/Python/前端工程师按需驻场"
            },
            {
              title: "定制开发",
              icon: <CodeOutlined />,
              desc: "小程序/APP/Web系统精准交付"
            }
          ].map((service, index) => (
            <Col key={index} xs={24} sm={24} md={8}>
              <motion.div
                whileHover={{ y: -8 }}
                transition={{ duration: 0.2 }}
              >
                <Card className="service-card">
                  {service.icon}
                  <Title level={3}>{service.title}</Title>
                  <Paragraph>{service.desc}</Paragraph>
                </Card>
              </motion.div>
            </Col>
          ))}
        </Row>
      </motion.div>

      {/* Statistics */}
      <motion.div 
        className="statistics-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={8}>
            <div className="statistic-item">
              <Statistic 
                title="核心技术栈" 
                value={6} 
                suffix="+" 
                prefix={<CodeOutlined />}
              />
              <Paragraph className="stat-desc">
                Java/PHP/Python/Node.js/React/Vue/Flutter
              </Paragraph>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="statistic-item">
              <Statistic 
                title="平台对接经验" 
                value={8} 
                suffix="+" 
                prefix={<ApiOutlined />}
              />
              <Paragraph className="stat-desc">
                美团/饿了么/淘宝/京东等主流平台
              </Paragraph>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="statistic-item">
              <Statistic 
                title="自研解决方案" 
                value={3} 
                suffix="+" 
                prefix={<SolutionOutlined />}
              />
              <Paragraph className="stat-desc">
                分销系统/直播带货/本地生活服务
              </Paragraph>
            </div>
          </Col>
        </Row>
      </motion.div>

      {/* QRCode Modal */}
      <QRCodeModal 
        visible={isQRCodeVisible}
        onClose={() => setIsQRCodeVisible(false)}
      />
    </div>
  );
};

export default Home; 