import React from 'react';
import { Typography, Row, Col, Card, List, Tag } from 'antd';
import {
  RocketOutlined,
  TeamOutlined,
  CodeOutlined,
  CheckCircleOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';

const { Title, Paragraph } = Typography;

const Services: React.FC = () => {
  const coreServices = [
    {
      icon: <RocketOutlined className="service-icon" />,
      title: '软件项目全包',
      desc: '从需求分析到上线运维，一站式服务',
      color: '#1890ff',
      details: [
        {
          title: '前期规划',
          items: ['需求分析', '产品规划', '系统架构设计']
        },
        {
          title: '开发实现',
          items: ['全栈开发', '测试与质量保证', '部署上线']
        },
        {
          title: '服务保障',
          items: ['项目经理跟进', '源码交付', '免费维护']
        }
      ]
    },
    {
      icon: <TeamOutlined className="service-icon" />,
      title: '人力外派',
      desc: 'Java/PHP/Python/前端工程师按需驻场',
      color: '#722ed1',
      details: [
        {
          title: '技术方向',
          items: ['Java工程师', 'PHP工程师', 'Python工程师', '前端工程师']
        },
        {
          title: '服务方式',
          items: ['驻场服务', '远程协作', '混合办公']
        },
        {
          title: '质量保证',
          items: ['人才背景审核', '周报制度', '技术保障']
        }
      ]
    },
    {
      icon: <CodeOutlined className="service-icon" />,
      title: '定制开发',
      desc: '小程序/APP/Web系统精准交付',
      color: '#13c2c2',
      details: [
        {
          title: '产品类型',
          items: ['微信小程序', '移动应用', '企业网站', '管理系统']
        },
        {
          title: '开发流程',
          items: ['需求确认', '迭代开发', '测试交付']
        },
        {
          title: '服务保障',
          items: ['进度透明', '及时交付', '一对一服务']
        }
      ]
    }
  ];

  return (
    <div className="services-page">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="page-header"
      >
        <Title>我们的服务</Title>
        <Paragraph className="page-desc">
          专业的技术团队，为您提供全方位的软件开发服务
        </Paragraph>
      </motion.div>

      <Row gutter={[24, 24]}>
        {coreServices.map((service, index) => (
          <Col xs={24} key={index}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <Card 
                className="service-card"
                style={{ borderTop: `4px solid ${service.color}` }}
              >
                <Row gutter={[24, 24]} align="middle">
                  <Col xs={24} md={8}>
                    <div className="service-intro" style={{ color: service.color }}>
                      {service.icon}
                      <Title level={3}>{service.title}</Title>
                      <Paragraph>{service.desc}</Paragraph>
                    </div>
                  </Col>
                  <Col xs={24} md={16}>
                    <Row gutter={[16, 16]}>
                      {service.details.map((detail, idx) => (
                        <Col xs={24} md={8} key={idx}>
                          <Card className="detail-card">
                            <Title level={4}>{detail.title}</Title>
                            <List
                              dataSource={detail.items}
                              renderItem={item => (
                                <List.Item className="detail-item">
                                  <ArrowRightOutlined /> {item}
                                </List.Item>
                              )}
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Services; 